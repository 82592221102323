import styled, { keyframes } from 'styled-components';

const rotate = () => keyframes`
  0%{
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background: ${({ theme }) => theme.colors.primaryColor};

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0.5rem transparent solid;
    border-radius: 50%;
  }

  &::after {
    width: 6rem;
    height: 6rem;
    border-left: 0.5rem solid ${({ theme }) => theme.colors.secondaryColor};
    border-top: 0.5rem solid ${({ theme }) => theme.colors.secondaryColor};
    animation: ${rotate()} 800ms linear infinite;
  }

  &::before {
    width: 2rem;
    height: 2rem;
    border-left: 0.5rem solid ${({ theme }) => theme.colors.secondaryColor};
    border-top: 0.5rem solid ${({ theme }) => theme.colors.secondaryColor};
    animation: ${rotate()} 1s linear infinite reverse;
  }
`;
