/* eslint-disable no-unused-vars */
import P from 'prop-types';
import * as Styled from './styles';

import { SectionBackground } from './../SectionBackground';
import { Heading } from './../Heading';
import { TextComponent } from './../TextComponent';

export const GridTwoColumn = ({
  title,
  text,
  srcImg,
  backgroundDark = false,
  sectionId = '',
}) => {
  return (
    <SectionBackground backgroundDark={backgroundDark} sectionId={sectionId}>
      <Styled.Container>
        <Styled.TextContainer>
          <Heading uppercase colorDark={!backgroundDark} as="h2">
            {title}
          </Heading>
          <TextComponent>{text}</TextComponent>
        </Styled.TextContainer>
        <Styled.ImageContainer>
          <Styled.Image src={srcImg} alt={title} />
        </Styled.ImageContainer>
      </Styled.Container>
    </SectionBackground>
  );
};

GridTwoColumn.propTypes = {
  title: P.string.isRequired,
  text: P.string.isRequired,
  srcImg: P.string.isRequired,
  backgroundDark: P.bool,
  sectionId: P.string,
};
